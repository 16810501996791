import { useState } from 'react';
import {
  Flex,
  Box,
  Text,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { MdInfo, MdPerson, MdOutlineShoppingBag } from 'react-icons/md';
import Image from 'next/image';
import axios from 'axios';

export function convertString(input) {
  // Convert the input string to lower case and split by underscore
  let words = input.toLowerCase().split('_');

  // Capitalize the first letter of each word and join them with spaces
  let converted = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return converted;
}

const ServiceGroups = ({
  option,
  onChange,
  index,
  setCurrentIndex,
  currentIndex,
  baseUrl,
  token,
}) => {
  const maxPrice = option.price * 1.2;

  const [tariffDay, setTariffDay] = useState([]);
  const [tariffNight, setTariffNight] = useState([]);

  const headers = {
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: `Basic ${token}`,
  };

  const {
    isOpen: isServiceInfoOpen,
    onOpen: onServiceInfoOpen,
    onClose: onServiceInfoClose,
  } = useDisclosure();

  const changeService = () => {
    setCurrentIndex(index === currentIndex ? null : index);
    onChange();
  };

  const getTarrifsService = (data) => {
    onServiceInfoOpen();

    axios
      .get(`${baseUrl}/driver/price/get_tariffs?service=${data}`, {
        headers,
      })
      .then(({ data }) => {
        let tariffsDay = [];
        data.tariffs[1].steps.map((_item) => {
          let tariff = {
            baseFare: (_item.step_distance / 1.6 / 1000).toFixed(0),
            meters: (_item.step_distance / 1.6 / 1000).toFixed(0),
            minutes: _item.step_time / 60,
            threshold: _item.threshold,
            price: _item.step_price,
          };
          tariffsDay.push(tariff);
        });
        setTariffDay(tariffsDay);

        let tariffsNight = [];
        data.tariffs[0].steps.map((_item) => {
          let tariff = {
            baseFare: (_item.step_distance / 1.6 / 1000).toFixed(0),
            meters: (_item.step_distance / 1.6 / 1000).toFixed(0),
            minutes: _item.step_time / 60,
            threshold: _item.threshold,
            price: _item.step_price,
          };
          tariffsNight.push(tariff);
        });
        setTariffNight(tariffsNight);
      })
      .catch((e) => console.log(e));
  };

  return (
    <Box as="label" key={index} display={'flex'}>
      <Box
        width={'100%'}
        cursor="pointer"
        shadow="sm"
        rounded={'md'}
        px={5}
        py={3}
        onClick={() => changeService()}
        backgroundColor={index === currentIndex ? 'gray.100' : ''}
      >
        <Flex alignItems="center" justifyContent={'space-between'}>
          <Image
            src={`/images/${option.image}.png`}
            alt={option.image}
            width={60}
            height={60}
            objectFit="contain"
          />
          <Flex alignItems={'center'}>
            <Text pl={5} m={0} fontSize={12}>
              {option.name === 'MPV' ? 'MPV' : convertString(option.name)}
            </Text>
            <Icon
              ml={1}
              alignSelf={'center'}
              as={MdInfo}
              w={4}
              h={4}
              cursor={'pointer'}
              onClick={() => getTarrifsService(option.name)}
            />
          </Flex>
          <Text pl={5} m={0} fontSize={14}>
            {`£${(option.price / 100).toFixed(2)}`} -{' '}
            {`£${(maxPrice / 100).toFixed(2)}`}
          </Text>
        </Flex>
      </Box>

      <Modal isOpen={isServiceInfoOpen} onClose={onServiceInfoClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Box padding={5}>
              <Box pt={5}>
                <Text
                  fontSize={10}
                  color={'grey'}
                >{`Your price estimation is available on day and time of your request and
                    it can differ from final price. If the final price is higher than
                    expected, possible reasons may be due to: Traffic delays, GPS/network
                    issues, Additional fees such as airport fees, Extended stopovers
                    during the trip or Driver taking a different route. If you have
                    further concerns regarding the final price, please contact our Support
                    Team for further assistance.`}</Text>
                <Box mt={2}>
                  <Text fontSize={12}> Day </Text>
                  {tariffDay.length > 0 && (
                    <>
                      <Box>
                        <Text fontSize={12}>
                          {' '}
                          1. - £{tariffDay[0]?.price / 100} basefare price (up
                          to {Number(tariffDay[0]?.meters) / 10} miles) or{' '}
                          {tariffDay[0]?.minutes} minutes{' '}
                        </Text>
                        {tariffDay.length > 2 ? (
                          <>
                            <Text fontSize={12}>
                              {' '}
                              2. - £{Number(tariffDay[1]?.price / 100) * 10} per
                              mile for any additional mile or{' '}
                              {tariffDay[1]?.minutes * 10} minutes up to{' '}
                              {(Number(tariffDay[1]?.threshold) / 1600).toFixed(
                                0
                              )}{' '}
                              miles or {tariffDay[1]?.minutes * 10 * 2} minutes
                            </Text>
                            <Text fontSize={12}>
                              {' '}
                              3. - £
                              {(Number(tariffDay[2]?.price / 100) * 10).toFixed(
                                1
                              )}{' '}
                              permile for any additional mile or{' '}
                              {tariffDay[2]?.minutes * 10} minutes{' '}
                            </Text>
                          </>
                        ) : (
                          <>
                            <Text fontSize={12}>
                              {' '}
                              2. - £{Number(tariffDay[1]?.price / 100) * 10} per
                              mile for any additional mile or{' '}
                              {tariffDay[1]?.minutes * 10} minutes up to{' '}
                              {(Number(tariffDay[1]?.threshold) / 1600).toFixed(
                                0
                              )}{' '}
                              miles or {tariffDay[1]?.minutes * 10 * 2} minutes
                            </Text>
                          </>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
                <Box marginTop={5}>
                  <Text fontSize={12}> Night </Text>
                  {tariffNight.length > 0 && (
                    <Box>
                      <Text fontSize={12}>
                        {' '}
                        1. - £{tariffNight[0]?.price / 100} basefare price (up
                        to {Number(tariffNight[0]?.meters) / 10} miles) or{' '}
                        {tariffNight[0]?.minutes} minutes{' '}
                      </Text>
                      {tariffNight.length > 2 ? (
                        <>
                          <Text fontSize={12}>
                            {' '}
                            2. - £{Number(tariffNight[1]?.price / 100) * 10} per
                            mile for any additional mile or{' '}
                            {tariffNight[1]?.minutes * 10} minutes up to{' '}
                            {(Number(tariffNight[1]?.threshold) / 1600).toFixed(
                              0
                            )}{' '}
                            miles or {tariffNight[1]?.minutes * 10 * 2} minutes
                          </Text>
                          <Text fontSize={12}>
                            {' '}
                            3. - £
                            {(Number(tariffNight[2]?.price / 100) * 10).toFixed(
                              1
                            )}{' '}
                            permile for any additional mile or{' '}
                            {tariffNight[2]?.minutes * 10} minutes{' '}
                          </Text>
                        </>
                      ) : (
                        <>
                          <Text fontSize={12}>
                            {' '}
                            2. - £{Number(tariffNight[1]?.price / 100) * 10} per
                            mile for any additional mile or{' '}
                            {tariffNight[1]?.minutes * 10} minutes up to{' '}
                            {(Number(tariffNight[1]?.threshold) / 1600).toFixed(
                              0
                            )}{' '}
                            miles or {tariffNight[1]?.minutes * 10 * 2} minutes
                          </Text>
                        </>
                      )}
                    </Box>
                  )}
                </Box>
                <Box marginTop={5}>
                  <Text fontSize={10} color={'grey'}>
                    {' '}
                    Additional waiting time charges may apply to your trip fair
                    if the driver has waited for longer than:{' '}
                  </Text>
                  <Text fontSize={10} color={'grey'}>
                    {' '}
                    - Pick up in town, 15 minutes free time (£0.8/min after){' '}
                  </Text>
                  <Text fontSize={10} color={'grey'}>
                    {' '}
                    - Pick up Airport, 30 minutes free time (£0.8/min after){' '}
                  </Text>
                </Box>
                <Box mt={5}>
                  <Box display={'flex'}>
                    <Icon as={MdPerson} w={4} h={4} color={'blackAlpha.800'} />
                    <Text ml={2} fontSize={12}>
                      2 Passengers
                    </Text>
                  </Box>
                  <Box display={'flex'}>
                    <Icon
                      as={MdOutlineShoppingBag}
                      w={4}
                      h={4}
                      color={'blackAlpha.800'}
                    />
                    <Text ml={2} fontSize={12}>
                      1 Check-In Luggages
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ServiceGroups;
